import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="px-80 mt-12 mb-20 gap-y-4 flex flex-col xl:px-56 lg:px-24 md:px-12 sm:px-6">
      <h1 className="font-semibold text-3xl">Politica de Confidențialitate</h1>
      <p>
        Prezenții Termeni și Condiții de utilizare a platformei online
        justfix.md sunt aplicabile comenzilor pentru achiziționarea produselor/
        serviciilor oferite de compania LISTRU GHEORGHE I.I. prin intermediul
        site-ului.
      </p>
      <p>
        Pentru folosirea în bune condiții a site-ului, vă recomandăm
        familiarizarea cu atenție a Termenilor și Condițiilor prezentate. Ne
        rezervăm dreptul de a face modificări ale acestor prevederi, fără o
        notificare prealabilă.
      </p>
      <h2 className="mt-2 text-xl font-medium">DISPOZIȚII GENERALE</h2>
      <p>
        Acest site este deținut de Listru Gheorghe I.I. La înregistrarea
        comenzii in magazinul online, Cumpărătorul accepta Termeni si Condițiile
        de vânzare a bunurilor/produselor și/sau prestare a serviciilor care
        sunt bazați pe legislația RM (in continuare – Termeni și Condiții)
        prezentate mai jos.
      </p>
      <p>
        Utilizarea website-ului presupune acceptarea Termeni si Condiții propuse
        de către Listru Gheorghe I.I., în conformitate cu Legea nr.284/2004
        privind Comerțul Electronic. Relațiile dintre client si vânzător sunt
        stabilite în baza Legii nr.105/2003 cu privire la Protecția drepturilor
        consumatorului, si a altor acte normative adoptate în conformitate cu
        aceasta.
      </p>
      <p>
        Vânzătorul își rezerva dreptul de a modifica Termenii si Condițiile
        date, iar Cumpărătorul este obligat sa monitorizeze modificările.
      </p>
      <h2 className="mt-2 text-xl font-medium">
        PROTECȚIA DATELOR CU CARACTER PERSONAL
      </h2>
      <p>
        Prin utilizarea site-ului justfix.md, sunteți de acord în mod automat cu
        colectarea și prelucrarea datelor cu caracter personal, care sunt
        necesare pentru procesarea/confirmarea/executarea comenzilor.
      </p>
      <p>
        Datele personale se prelucrează doar pentru scopuri legitime, precum
        acela de a furniza produsele pe care le comanzi, promoții, google
        analytics, cookies și notificări / newsletter.
      </p>
      <p>
        Toată informația ce conține date cu caracter personal este stocată și
        utilizată doar pentru perioada necesară atingerii scopurilor pentru care
        a fost colectată și în conformitate cu prevederile stabilite de Legea
        nr. 133/2011 privind protecția datelor cu caracter personal.
      </p>
      <p>
        Noi folosim măsuri de securitate comerciale pentru a preveni accesul
        neautorizat, menținerea exactității datelor și pentru asigurarea
        utilizării corecte a informației de pe site. Transmiterea datelor pe
        internet sau prin rețea wireless nu poate fi protejata în totalitate.
        Prin urmare, la fel ca alte companii, noi nu putem garanta securitatea
        informației pe care o oferă Cumpărătorului, astfel, el va face acest
        lucru pe propria răspundere.
      </p>
      <h2 className="mt-2 text-xl font-medium">ACHITAREA COMENZII</h2>
      <p>
        Achitarea comenzilor este posibilă prin numerar sau card la livrare,
      </p>
      <p>
        Toate achitările se procesează in moneda naționala - MDL (lei
        moldovenești). În cazul În care valuta operațiunii diferă de valuta
        achitării, convertirea sumei operațiunii se efectuează conform cursului
        la operațiunile cu carduri din ziua decontării, al băncii emitente.
      </p>
      <h2 className="mt-2 text-xl font-medium">
        LIVRAREA PRODUSELOR / MĂRFURILOR / SERVICIILOR
      </h2>
      <p>
        După înregistrarea comenzii, cumpărătorului i se oferă informația
        privind data preconizată de transmitere a comenzii de către
        curieri/serviciul de livrare.
      </p>
      <p>
        Livrarea în raza mun. Chișinău se efectuează în timp de 24 ore de la
        confirmarea a comenzii de către operatorii noștri. Livrarea se
        efectuează de la ora 11 până la ora 18 și este gratuită pentru comenzi
        de minim 500 MDL, pentru comenzi cu valoare mai mică se achită livrarea
        50 MDL (care va fi inclusă în suma totală a tranzacției/comenzii). În
        orașul Chișinău livarea este gratuită.
      </p>
      <p>
        În zilele de duminică sau sărbătoare națională nu se efectuează livrări.
      </p>
      <p>
        Termenul de livrare se poate extinde în anumite situații izolate (de
        exemplu: condiții meteo nefavorabile, perioadele sărbătorilor legale,
        probleme tehnice neprevăzute etc). Vă vom notifica în cazul în care
        întâmpinăm una dintre situațiile izolate în prealabil.
      </p>
      <p>
        Puteți alege orice adresa pentru livrare – acasă, în oficiu sau oricare
        altă adresă, fără achitări suplimentare. Noi acceptăm o singură adresă
        de livrare pentru fiecare comandă. În cazul în care aveți nevoie pentru
        a livra comanda pe diferite adrese, vă rugăm să înregistrați comenzi
        diferite pentru fiecare adresă.
      </p>
      <p>
        La primirea produselor, vă rugăm în prezența curierului să verificați
        integritatea ambalajului, prezența bonului fiscal. Orice pretenție
        ulterioară cu privire la aceste aspecte nu vor fi acceptate.
      </p>
      <h2 className="mt-2 text-xl font-medium">RETURNAREA PRODUSELOR</h2>
      <p>
        Conform noțiunii din Legea nr. 105-XV din 13 martie 2013 privind
        protecția consumatorilor Cumpărătorul are dreptul de a returna
        produs/marfa procurată din anumite motive în timp de 14 zile de la ziua
        achitării. Mijloacele bănești sunt returnate pe cardul de pe care s-a
        efectuat cumpărătura.
      </p>
      <p>
        Articolele ce nu pot fi schimbate sau returnate, în conformitate cu
        Anexa nr.2 a Hotărârii Guvernului №1465 din 08.12.2003
        (MO248-253/19.12.2003), în redacția Hotărârii Guvernului №1188 din
        02.11.2007 (МО175/09.11.2007).
      </p>
      <p>Contactele companiei in caz de retur: +373 060842266</p>
      <p>Termenul de procesare a cererii de retur: 24 ore</p>
      <p>
        Condițiile de retur: Produsul trebuie să fie în stare inițială, fără
        defecte tehnice sau estetice.
      </p>
      <h2 className="mt-2 text-xl font-medium">DATELE DE CONTACT</h2>
      <p>Denumirea juridica a companiei: Listru Gheorghe I.I.</p>
      <p>IDNO: 1024609004729</p>
      <p>Telefon de contact: +373 060842266</p>
      <p>Email de contact: office@justfix.md</p>
    </div>
  );
};

export default TermsAndConditions;
