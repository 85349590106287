import React from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

const FrequentlyAskedQuestions = () => {
  const questions = [
    {
      question: "Ce tipuri de servicii oferiți?",
      answer:
        "La JustFix, oferim servicii de reparație și profilaxie pentru calculatoare, console de jocuri și laptopuri. Acestea includ diagnosticare, înlocuire de componente, curățare, actualizări software, și optimizare a performanței.",
    },
    {
      question: "Cum pot solicita o reparație?",
      answer:
        "Pentru a solicita o reparație, te rugăm să ne contactezi prin formularul de pe site, telefonic, sau prin email. Vom aranja o preluare a echipamentului sau vom programa o vizită la domiciliul tău pentru diagnosticare și efectuarea reparației.",
    },
    {
      question: "Care sunt costurile asociate cu reparația?",
      answer:
        "Costul reparației variază în funcție de tipul și gravitatea problemei. După diagnosticarea echipamentului, îți vom oferi un estimat detaliat al costurilor înainte de a începe lucrările de reparație.",
    },
    {
      question: "Care este durata medie a unei reparații?",
      answer:
        "Timpul de reparație depinde de natura problemei și disponibilitatea pieselor de schimb. În general, majoritatea reparațiilor sunt finalizate într-un interval de 1-2 ore dacă venim la fața locului sau 1-2 zile dacă vă preluăm echipamentul. Te vom ține la curent cu progresul reparației.",
    },
    {
      question: "Oferiți garanție pentru serviciile oferite?",
      answer:
        "Da, oferim garanție pentru toate serviciile noastre. Aceasta acoperă atât componentele de schimb, cât și manopera, și are o durată de 30 de zile de la finalizarea reparației.",
    },
    {
      question: "Cum pot plăti pentru serviciile oferite?",
      answer:
        "Pentru a plăti pentru serviciile oferite, poți alege între numerar, card bancar, sau transfer bancar. Plata se face la finalizarea reparației, după ce echipamentul tău a fost reparat și testat.",
    },
    {
      question: "Cum pot contacta echipa de suport?",
      answer:
        "Poți contacta echipa noastră de suport prin intermediul butonului de solicitare a unei consultații, telefonic la +373 060842266, sau prin email la office@justfix.md. Suntem aici pentru a răspunde la întrebările tale și pentru a te asista în orice problemă ai avea.",
    },
    {
      question: "Care sunt orele în care lucrați?",
      answer:
        "Lucrăm de luni până vineri, între orele 9:00 și 18:00. În această perioadă, putem prelua echipamentul pentru diagnosticare sau reparație, sau poți contacta echipa noastră de suport pentru venirea la fața locului.",
    },
    {
      question: "Ce fac dacă nu sunt mulțumit de serviciile oferite?",
      answer:
        "Dacă nu ești mulțumit de serviciile oferite, te rugăm să ne contactezi imediat pentru a remedia situația. Suntem dedicați satisfacției clienților noștri și vom face tot posibilul pentru a rezolva orice problemă întâmpinată.",
    },
    {
      question: "Oferiți servicii de deplasare la domiciliu?",
      answer:
        "Da, oferim servicii de deplasare la domiciliu pentru reparații. Acestea pot fi solicitate la momentul programării și sunt disponibile contra unei taxe suplimentare.",
    },
    {
      question: "Oferiți servicii de reparație pentru firme?",
      answer:
        "Da, oferim servicii de reparație pentru firme și companii. Pentru mai multe detalii și pentru a discuta despre nevoile dumneavoastră, vă rugăm să ne contactați pentru a stabili o întâlnire.",
    },
    {
      question: "Cum pot obține o factură pentru serviciile oferite?",
      answer:
        "Pentru a obține o factură pentru serviciile oferite, te rugăm să ne contactezi și să ne furnizezi datele de facturare. Vom emite factura și ți-o vom înmâna după efectuarea lucrărilor.",
    },
    {
      question: "Oferiți servicii de mentenanță preventivă?",
      answer:
        "Da, oferim servicii de mentenanță preventivă pentru echipamentele IT. Acestea includ curățare, verificare, și optimizare a performanței pentru a preveni apariția problemelor și a asigura funcționarea corectă a echipamentului.",
    },
    {
      question: "Oferiți servicii de recuperare a datelor?",
      answer:
        "Da, oferim servicii de recuperare a datelor pentru echipamentele IT. Acestea includ recuperarea datelor de pe dispozitivele de stocare defecte sau corupte, și transferul acestora pe un alt dispozitiv sau mediu de stocare.",
    },
    {
      question: "Oferiți servicii de la distanță?",
      answer:
        "Da, oferim anumite servicii de la distanță pentru echipamentele IT. Acestea includ diagnosticare, configurare, și asistență tehnică online pentru a rezolva problemele fără a fi nevoie de prezența fizică a echipamentului.",
    },
    {
      question: "Cum pot achiziționa produse de la JustFix?",
      answer:
        "Pentru a achiziționa produse de la JustFix, te invităm să vizitezi secțiunea Magazin și adaugi produse în coșul de cumpărături. După ce ai adăugat toate produsele dorite, poți finaliza comanda și vei fi contactat pentru confirmare și livrare.",
    },
  ];
  const pageSEO = useSelector((state) => state.pageSEO);

  return (
    <div className="px-80 mb-20 mt-12 xl:px-56 lg:px-24 md:px-12 sm:px-6">
      <Helmet>
        <title>{pageSEO.title}</title>
        <meta name="description" content={pageSEO.description} />
        <meta name="keywords" content={pageSEO.keywords} />
        <link rel="canonical" href="https://justfix.md/intrebari-frecvente" />
      </Helmet>
      <h1 className="font-semibold text-3xl">Întrebări frecvente</h1>
      <div className="flex flex-col gap-y-4 mt-2">
        {questions.map((question, index) => (
          <div key={index} className="flex flex-col gap-y-2">
            <h3 className="font-medium text-lg">{question.question}</h3>
            <p className="text-black/75">{question.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
