import React, { useState, useEffect } from "react";
import order from "../static/images/order.png";
import shipping from "../static/images/shipping.png";
import payment from "../static/images/payment.png";
import gift from "../static/images/gift.png";

import axios from "axios";
import { api_url } from "../constants";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet-async";

import ProductCard from "../components/ProductCard";
import { useSelector } from "react-redux";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noProducts, setNoProducts] = useState(false);
  const pageSEO = useSelector((state) => state.pageSEO);

  useEffect(() => {
    axios
      .get(`${api_url}/products/`)
      .then((res) => {
        setProducts(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setNoProducts(true);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="px-80 flex flex-col gap-y-8 mt-12 mb-20 xl:px-56 lg:px-24 md:px-12 sm:px-6">
      <Helmet>
        <title>{pageSEO.title}</title>
        <meta name="description" content={pageSEO.description} />
        <meta name="keywords" content={pageSEO.keywords} />
        <link rel="canonical" href="https://justfix.md/produse" />
      </Helmet>

      <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-4 ">
        <div className="flex flex-col items-center bg-black/10 rounded-md px-4 py-8">
          <img src={order} alt={"comanda"} className="w-32" />

          <p className="text-black/75 text-center mt-4">
            Odată ce ai făcut comanda, te vom contacta pentru confirmare.
          </p>
        </div>
        <div className="flex flex-col items-center bg-black/10 rounded-md px-4 py-8">
          <img src={gift} alt={"cadou"} className="w-32" />

          <p className="text-black/75 text-center mt-4">
            Pentru fiecare comandă vei primi un cadou special de la noi.
          </p>
        </div>

        <div className="flex flex-col items-center bg-black/10 rounded-md px-4 py-8">
          <img src={shipping} alt={"livrare"} className="w-32" />

          <p className="text-black/75 text-center mt-4">
            Livrare gratuită în Chișinău și la comenzi de peste 500 Lei în
            Moldova.
          </p>
        </div>

        <div className="flex flex-col items-center bg-black/10 rounded-md px-4 py-8">
          <img src={payment} alt={"plata"} className="w-32" />
          <p className="text-black/75 text-center mt-4">
            Plata se face la primirea coletului, în numerar sau cu cardul.
          </p>
        </div>
      </div>
      {noProducts && (
        <h1 className="text-xl font-medium bg-black/10 w-full py-6 text-center ">
          Momentan nu avem produse disponibile. Vă rugăm reveniți mai târziu.
        </h1>
      )}
      <div className="grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8 gap-y-4">
        {isLoading
          ? Array.from({ length: 6 }).map((_, index) => (
              <div key={index} className="flex flex-col bg-black/10 rounded-md">
                <Skeleton height={230} />
                <Skeleton height={50} />
              </div>
            ))
          : products.map((product) => <ProductCard product={product} />)}
      </div>
    </div>
  );
};

export default Products;
