const debug = false;
const base_url = debug ? "http://localhost:8000" : "";
export const api_url = base_url + "/api";
export const media_url = base_url + (debug ? "" : "/media");
export const auth_url = base_url + "/auth";
export const jwt_url = base_url + "/jwt";

export const defaultPageSEO = {
  title: "JustFix - Reparație si Mentenanță Electronice",
  title_ru: "JustFix - Reparație si Mentenanță Electronice",
  description:
    "Servicii de reparare și diagnosticare a calculatorului, laptopului și altor dispozitive electronice",
  description_ru: "Сервис по ремонту и диагностике компьютера",
  keywords:
    "reparare, calculator, reparare laptop, reparare consola, reparatie calculatoare, reparatie calculator chisinau, reparatie laptop chisinau, instalare windows chisinau, curatare laptop, curatare calculator, service chisinau",
  keywords_ru: "ремонт, компьютер, ноутбук, пк, сервис, ремонт",
};

export function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  window.gtag("event", "conversion", {
    send_to: "AW-16695939970/64-rCOut89EZEIKnn5k-",
    value: 1.0,
    currency: "USD",
    event_callback: callback,
  });
  return false;
}

export const urls = [
  {
    url: "/intrebari-frecvente",
    url_ru: "/ru/chasto-zadavaemye-voprosy",
  },
  {
    url: "/contact",
    url_ru: "/ru/kontakt",
  },
  {
    url: "/servicii",
    url_ru: "/ru/uslugi",
  },
  {
    url: "/servicii-la-distanta",
    url_ru: "/ru/uslugi-na-rasstoyanii",
  },
  {
    url: "/",
    url_ru: "/ru",
  },
];

export const oraseMoldova = [
  "Chișinău",
  "Bălți",
  "Cahul",
  "Orhei",
  "Tiraspol",
  "Comrat",
  "Ungheni",
  "Soroca",
  "Edineț",
  "Căușeni",
  "Hîncești",
  "Anenii Noi",
  "Strășeni",
  "Ialoveni",
  "Drochia",
  "Nisporeni",
  "Florești",
  "Cimișlia",
  "Sîngerei",
  "Rîbnița",
];
