import { createSlice } from "@reduxjs/toolkit";

//list of ids of products in the cart
const initialState = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const index = state.findIndex(
        (product) => product.id === action.payload.id
      );
      if (index !== -1) {
        state[index].quantity += action.payload.quantity;
        localStorage.setItem("cart", JSON.stringify(state));
        return;
      }
      state.push(action.payload);
      localStorage.setItem("cart", JSON.stringify(state));
    },
    removeFromCart: (state, action) => {
      state = state.filter((product) => product.id !== action.payload);
      localStorage.setItem("cart", JSON.stringify(state));
      return state;
    },
    changeQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      const index = state.findIndex((item) => item.id === id);
      state[index].quantity = quantity;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    clearCart: (state) => {
      state = [];
      localStorage.setItem("cart", JSON.stringify(state));
      return state;
    },
  },
});

export const { addToCart, removeFromCart, changeQuantity, clearCart } =
  cartSlice.actions;
export default cartSlice.reducer;
