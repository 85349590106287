import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./languageSlice";
import globalMessageReducer from "./globalMessageSlice";
import modalsOpenerReducer from "./modalsOpenerSlice";
import pageSEOReducer from "./pageSEOSlice";
import cartReducer from "./cartSlice";

export const store = configureStore({
  reducer: {
    globalMessage: globalMessageReducer,
    language: languageReducer,
    modalsOpener: modalsOpenerReducer,
    pageSEO: pageSEOReducer,
    cart: cartReducer,
  },
});
