import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import { changeQuantity, removeFromCart, clearCart } from "../redux/cartSlice";
import { Link } from "react-router-dom";
import axios from "axios";
import { api_url, media_url, oraseMoldova } from "../constants";
import { MdDeleteOutline } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import { LuMinus } from "react-icons/lu";
import Skeleton from "react-loading-skeleton";
import { setGlobalErrorMessage } from "../redux/globalMessageSlice";
import { FiGift } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { motion } from "framer-motion";

const Cart = () => {
  const cart = useSelector((state) => state.cart);
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [shipping, setShipping] = useState(0);
  const [raion, setRaion] = useState("");
  const [localitate, setLocalitate] = useState("");
  const [adresa, setAdresa] = useState("");
  const [nume, setNume] = useState("");
  const [telefon, setTelefon] = useState("");
  const [comentarii, setComentarii] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isOrdering, setIsOrdering] = useState(false);
  const [isOrdered, setIsOrdered] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeData, setPromoCodeData] = useState(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const pageSEO = useSelector((state) => state.pageSEO);

  useEffect(() => {
    let calculatedTotal = total + shipping;
    if (promoCodeData) {
      calculatedTotal -= total * (promoCodeData.discount / 100);
    }
    setGrandTotal(calculatedTotal);
  }, [total, shipping, promoCodeData]);

  useEffect(() => {
    setTotal(
      products.reduce((acc, item) => acc + item.price * item.quantity, 0)
    );
  }, [products]);

  useEffect(() => {
    if (cart.length === 0) {
      setIsLoading(false);
      return;
    }
    axios
      .post(
        api_url + "/cart/",
        cart.map((item) => item.id)
      )
      .then((res) => {
        const resProducts = res.data.map((item) => ({
          ...item,
          quantity: cart.find((cartItem) => cartItem.id === item.id).quantity,
        }));
        setProducts(resProducts);
        setTotal(
          resProducts.reduce((acc, item) => acc + item.price * item.quantity, 0)
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (raion === "Chișinău" || total >= 500) {
      setShipping(0);
    } else {
      setShipping(50);
    }
  }, [raion, total]);

  function OrderHandler() {
    if (products.length === 0) {
      dispatch(setGlobalErrorMessage("Coșul tău este gol."));
      return;
    }
    if (
      raion === "" ||
      localitate === "" ||
      adresa === "" ||
      nume === "" ||
      telefon === ""
    ) {
      dispatch(
        setGlobalErrorMessage("Te rugăm să completezi toate câmpurile.")
      );
      return;
    }
    if (!isTermsAccepted) {
      dispatch(
        setGlobalErrorMessage(
          "Nu ești de acord cu Termenii și Condițiile noastre."
        )
      );
      return;
    }
    setIsOrdering(true);
    axios
      .post(api_url + "/order/", {
        products: products.map((product) => ({
          id: product.id,
          quantity: product.quantity,
        })),
        state: raion,
        city: localitate,
        address: adresa,
        name: nume,
        phone: telefon,
        comments: comentarii,
        promo: promoCodeData ? promoCodeData.code : null,
      })
      .then((res) => {
        console.log(res);
        setIsOrdering(false);
        setIsOrdered(true);
        dispatch(clearCart());
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function VerifyPromoCode() {
    axios
      .post(api_url + "/promo/order/", { code: promoCode })
      .then((res) => {
        setPromoCodeData(res.data);
      })
      .catch((err) => {
        setPromoCodeData(null);
      });
  }

  return (
    <div className="px-80 flex flex-col gap-y-4 mt-12 mb-20 xl:px-56 lg:px-24 md:px-12 sm:px-6">
      <Helmet>
        <title>{pageSEO.title}</title>
        <meta name="description" content={pageSEO.description} />
        <meta name="keywords" content={pageSEO.keywords} />
        <link rel="canonical" href="https://justfix.md/cos" />
      </Helmet>
      <h1 className="text-3xl font-bold">Coșul tău</h1>
      {isOrdered ? (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex flex-col gap-y-4 items-center bg-black/10 rounded-md py-6"
        >
          <IoIosCheckmarkCircleOutline className="text-6xl text-primary" />
          <p className="text-xl">
            Comanda a fost plasată cu succes! Vei fi contactat în cel mai scurt
            timp posibil pentru confirmare.
          </p>
        </motion.div>
      ) : isLoading ? (
        <div className="flex flex-col gap-y-4">
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </div>
      ) : products.length === 0 ? (
        <div className="flex flex-col gap-y-4 items-center bg-black/10 rounded-md py-6">
          <p className="text-xl">Coșul tău este gol. Adaugă produse acum!</p>
          <Link to="/produse">
            <button className="bg-primary text-white w-fit px-4 py-1 rounded-full">
              Vezi produse
            </button>
          </Link>
        </div>
      ) : (
        <div className="flex gap-x-8 sm:flex-col md:flex-col">
          <div className="w-2/3 flex flex-col gap-y-6 sm:w-full md:w-full">
            <div className="flex flex-col gap-y-4 rounded-md bg-black/10 p-4">
              {products.map((product) => (
                <div className="flex gap-x-4 border-b-2 border-black border-solid">
                  <img
                    className="w-40 h-auto rounded-md"
                    src={media_url + product.image}
                  />
                  <div className="w-full">
                    <h2 className="text-xl font-semibold">{product.name}</h2>
                    <p className="text-lg">{product.price} Lei</p>
                    <div className="border-2 mt-2 border-secondary w-fit justify-between border-solid rounded-full flex">
                      <button
                        onClick={() => {
                          if (product.quantity <= 1) return;
                          dispatch(
                            changeQuantity({
                              id: product.id,
                              quantity: product.quantity - 1,
                            })
                          );

                          setProducts(
                            products.map((item) =>
                              item.id === product.id
                                ? { ...item, quantity: item.quantity - 1 }
                                : item
                            )
                          );
                        }}
                        className="p-1 px-2"
                      >
                        <LuMinus />
                      </button>
                      <input
                        disabled={isOrdering}
                        type="number"
                        min="1"
                        max="1000"
                        value={product.quantity}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (value < 1 || value > 1000) return;
                          dispatch(
                            changeQuantity({
                              id: product.id,
                              quantity: value,
                            })
                          );
                          setProducts(
                            products.map((item) =>
                              item.id === product.id
                                ? { ...item, quantity: value }
                                : item
                            )
                          );
                        }}
                        className="p-1 bg-transparent outline-none w-10 text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      />
                      <button
                        onClick={() => {
                          if (product.quantity >= 1000) return;
                          dispatch(
                            changeQuantity({
                              id: product.id,
                              quantity: product.quantity + 1,
                            })
                          );

                          setProducts(
                            products.map((item) =>
                              item.id === product.id
                                ? { ...item, quantity: item.quantity + 1 }
                                : item
                            )
                          );
                        }}
                        className="p-1 px-2"
                      >
                        <GoPlus />
                      </button>
                    </div>
                    <div className="w-fit gap-x-4 mt-auto items-baseline ml-auto hidden sm:flex flex-col">
                      <button
                        onClick={() => {
                          setProducts(
                            products.filter((item) => item.id !== product.id)
                          );
                          dispatch(removeFromCart(product.id));

                          setTotal(
                            products.reduce(
                              (acc, item) => acc + item.price * item.quantity,
                              0
                            )
                          );
                        }}
                        className="bg-red-500 text-white w-fit ml-auto p-2 rounded-full"
                      >
                        <MdDeleteOutline className="text-2xl" />
                      </button>
                      <p className="text-xl font-semibold">
                        {product.quantity * product.price} Lei
                      </p>
                    </div>
                  </div>
                  <div className="flex w-fit flex-col ml-auto gap-y-4 sm:hidden">
                    <p className="text-xl font-semibold whitespace-nowrap	sm:whitespace-normal">
                      {product.quantity * product.price} Lei
                    </p>
                    <button
                      onClick={() => {
                        setProducts(
                          products.filter((item) => item.id !== product.id)
                        );
                        dispatch(removeFromCart(product.id));

                        setTotal(
                          products.reduce(
                            (acc, item) => acc + item.price * item.quantity,
                            0
                          )
                        );
                      }}
                      className="bg-red-500 text-white w-fit ml-auto p-2 rounded-full"
                    >
                      <MdDeleteOutline className="text-2xl" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="bg-black/10 rounded-md p-4">
              <h2 className="text-2xl font-semibold">Date de Contact</h2>
              <form className="grid grid-cols-2 gap-4 mt-2 sm:grid-cols-1">
                <input
                  name="name"
                  id="name"
                  disabled={isOrdering}
                  onChange={(e) => setNume(e.target.value)}
                  value={nume}
                  className="form-input"
                  placeholder="Nume complet"
                />
                <input
                  name="phone"
                  id="phone"
                  disabled={isOrdering}
                  onChange={(e) => setTelefon(e.target.value)}
                  value={telefon}
                  className="form-input"
                  placeholder="Număr de telefon"
                />
                <select
                  name="state"
                  id="state"
                  disabled={isOrdering}
                  onChange={(e) => setRaion(e.target.value)}
                  value={raion}
                  placeholder="Raion"
                  className="form-input"
                >
                  <option value="" disabled selected>
                    Raion
                  </option>
                  {oraseMoldova.map((oras) => (
                    <option value={oras}>{oras}</option>
                  ))}
                </select>
                <input
                  name="city"
                  id="city"
                  disabled={isOrdering}
                  onChange={(e) => setLocalitate(e.target.value)}
                  value={localitate}
                  className="form-input"
                  placeholder="Localitate"
                />
                <input
                  name="address"
                  id="address"
                  disabled={isOrdering}
                  onChange={(e) => setAdresa(e.target.value)}
                  value={adresa}
                  className="form-input"
                  placeholder="Adresă"
                />
                <textarea
                  name="comments"
                  id="comments"
                  disabled={isOrdering}
                  onChange={(e) => setComentarii(e.target.value)}
                  value={comentarii}
                  className="form-input col-span-2 h-28 sm:col-span-1"
                  placeholder="Comentarii (opțional)"
                />
              </form>
            </div>
          </div>
          <div className="relative w-1/3 sm:w-full md:w-full sm:mt-6 md:mt-6">
            <div className="bg-black/10 rounded-md p-4 gap-y-2 flex flex-col">
              <h2 className="text-2xl font-semibold">Total</h2>
              <div className="flex justify-between">
                <p>Produse</p>
                <p>{total} Lei</p>
              </div>
              {promoCodeData && (
                <div className="flex justify-between">
                  <p>Reducere</p>
                  <p>
                    -{(total * (promoCodeData.discount / 100)).toFixed(0)} Lei
                  </p>
                </div>
              )}
              <div className="flex justify-between">
                <p>Livrare</p>
                <p>{shipping === 0 ? "Gratuit" : `${shipping} Lei`}</p>
              </div>
              <div className="flex justify-between">
                <p>Total</p>
                <p>{grandTotal} Lei</p>
              </div>
              {!promoCodeData && (
                <div className="relative">
                  <input
                    disabled={isOrdering}
                    type="text"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    placeholder="Promo cod (dacă aveți)"
                    className="form-input w-full"
                  />
                  <button
                    onClick={VerifyPromoCode}
                    className="absolute right-0 top-0 h-full bg-primary text-white rounded-r-md border-solid border-2 border-black border-l-0 px-4"
                  >
                    Aplică
                  </button>
                </div>
              )}

              <button
                onClick={() => setIsTermsAccepted(!isTermsAccepted)}
                className="flex gap-x-2 items-center mt-4"
              >
                <div
                  className={
                    "w-4 h-4 border-1 relative border-black border-solid " +
                    (isTermsAccepted ? "bg-primary border-white" : "bg-white")
                  }
                >
                  {isTermsAccepted && (
                    <FaCheck className="absolute-center text-white text-sm" />
                  )}
                </div>
                <p>
                  Sunt de acord cu{" "}
                  <Link to="/termeni-si-conditii" className="text-primary">
                    Termenii și Condițiile
                  </Link>
                </p>
              </button>
              <button
                onClick={OrderHandler}
                className="bg-primary text-white w-full p-2 rounded-full"
              >
                Finalizează comanda
              </button>
              <div className="mt-2">
                + 1{" "}
                <FiGift className="text-xl inline-block mb-1 text-primary" />{" "}
                cadou special pentru fiecare comandă.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
