import React, { useEffect, useState } from "react";
import { MdPhone } from "react-icons/md";
import { IoMailSharp } from "react-icons/io5";
import { HiFlag } from "react-icons/hi2";
import logo from "../static/images/logo.png";
import { Link, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { changeLanguage } from "../redux/languageSlice";
import { openRequestModal } from "../redux/modalsOpenerSlice";
import { GiHamburgerMenu } from "react-icons/gi";
import { motion } from "framer-motion";
import axios from "axios";
import { api_url } from "../constants";
import parse from "html-react-parser";
import { BsCart3 } from "react-icons/bs";

const UserLayout = () => {
  // const [languageOpen, setLanguageOpen] = useState(false);
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [promotion, setPromotion] = useState(null);
  useEffect(() => {
    axios
      .get(api_url + "/promotion/")
      .then((res) => {
        setPromotion(res.data);
      })
      .catch((err) => {});
  }, []);
  const cart = useSelector((state) => state.cart);

  return (
    <div className="flex flex-col min-h-screen">
      <header>
        <div className="flex px-80 xl:px-56 lg:px-24 md:px-12 sm:px-6 justify-between py-3 font-medium bg-surface text-white">
          <div className="flex gap-x-10 items-center">
            <div className="gap-x-2 flex items-center">
              <MdPhone className="text-primary text-xl" /> +373 060842266
            </div>
            <div className="gap-x-2 flex items-center">
              <IoMailSharp className="text-primary text-xl" /> office@justfix.md
            </div>
          </div>
          {/* <div className="relative flex flex-col bg-surface">
            <div
              onClick={() => setLanguageOpen(!languageOpen)}
              className="flex cursor-pointer gap-x-2 items-center"
            >
              <HiFlag className="text-primary text-xl" />{" "}
              {language == "RO" ? "Română" : "Русский"}
            </div>
            <div
              className={
                "flex flex-col gap-y-2 z-10 translate-y-7 absolute pl-7 pr-2 py-1 bg-surface " +
                (languageOpen ? "opacity-100" : "opacity-0 pointer-events-none")
              }
            >
              <button
                onClick={() => {
                  dispatch(changeLanguage(language == "RO" ? "RU" : "RO"));
                  setLanguageOpen(false);
                }}
              >
                {language == "RO" ? "Русский" : "Română"}
              </button>
            </div>
          </div> */}
        </div>
        <div className="bg-secondary relative sm:flex-col gap-y-6 py-10 px-80 xl:px-56 lg:px-24 md:px-12 sm:px-6 flex justify-between items-center">
          <button
            onClick={() => setOpenSideMenu(!openSideMenu)}
            className="absolute top-5 left-5 hidden sm:block"
          >
            <GiHamburgerMenu className="text-3xl text-white" />
          </button>
          <h1 className="w-fit relative">
            <Link
              to={"/"}
              className="flex gap-x-1 text-white items-baseline font-semibold text-5xl"
            >
              <img src={logo} className="w-8 h-auto" alt="logo" />
              JustFix
            </Link>
          </h1>
          <div className="flex gap-x-4 items-center">
            <button
              onClick={() => {
                dispatch(openRequestModal());
              }}
              className="bg-primary text-white font-semibold text-xl md:text-lg md:py-2 py-3 rounded-full px-8"
            >
              {language == "RO"
                ? "Solicită o consultație gratuită"
                : "Запросить бесплатную консультацию"}
            </button>
            <Link
              to="/cos"
              className="relative sm:absolute sm:top-6 sm:right-6 group hover:scale-105 ease-in-out duration-200 transition-transform"
            >
              <BsCart3 className="text-white text-3xl" />
              {cart.length > 0 && (
                <div className="absolute -top-3 -right-3 bg-primary text-white rounded-full w-6 h-6 flex items-center text-sm font-semibold justify-center">
                  {cart.length}
                </div>
              )}
            </Link>
          </div>
        </div>
        <nav className="flex flex-wrap text-nowrap gap-y-4 break-keep whitespace-nowrap sm:hidden text-black/75 items-center gap-x-12 md:justify-between md:px-12 md:gap-x-8 py-4 drop-shadow-lg bg-white justify-center text-lg font-medium">
          <Link to={"/"}>{language == "RO" ? "Acasă" : "Домашняя"}</Link>
          <Link to={"/produse"}>Magazin</Link>
          <Link to={"/servicii"}>
            {language == "RO" ? "Servicii" : "Услуги"}
          </Link>
          <Link to={"/servicii-la-distanta"}>
            {language == "RO" ? "Servicii la distanță" : "Услуги на расстоянии"}
          </Link>
          <Link to={"/intrebari-frecvente"}>
            {language == "RO"
              ? "Întrebări Frecvente"
              : "Часто задаваемые вопросы"}
          </Link>
          <Link to={"/contact"}>
            {language == "RO" ? "Contactează-ne" : "Связаться с нами"}
          </Link>
        </nav>
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: promotion ? "auto" : 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="bg-primary text-white overflow-y-hidden sm:text-sm text-center font-medium  px-80 xl:px-56 lg:px-24 md:px-12 sm:px-6"
        >
          {promotion && (
            <p className="py-2">
              {parse(
                language == "RO"
                  ? promotion.description
                  : promotion.description_ru
              )}
            </p>
          )}
        </motion.div>
      </header>
      <Outlet />
      <div className="mt-auto bg-surface py-10 px-80 xl:px-56 lg:px-24 md:px-12 sm:px-6">
        <div className="w-2/3 sm:w-full sm:flex-col gap-y-6 md:w-full gap-x-10 flex">
          <div className="flex flex-col gap-y-2">
            <h3 className="text-2xl font-medium text-white">
              {language == "RO" ? "Informații utile" : "Полезная информация"}
            </h3>
            <ul className="text-tertiary break-keep	whitespace-nowrap  flex flex-col gap-y-2 font-medium">
              <li className="">
                <Link to={"/intrebari-frecvente"}>
                  {language == "RO"
                    ? "Întrebări frecvente"
                    : "Часто задаваемые вопросы"}
                </Link>
              </li>

              <li className="">
                <Link to="/politica-de-confidentialitate">
                  {language == "RO"
                    ? "Politica de confidențialitate"
                    : "Политика конфиденциальности"}
                </Link>
              </li>
              <li className="">
                <Link to="/termeni-si-conditii">Termeni și condiții</Link>
              </li>
              <li className="">
                <Link to="/toate-serviciile">Lista completă a serviciilor</Link>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-y-2">
            <h3 className="text-2xl font-medium text-white">
              {language == "RO"
                ? "Contacte / Parteneriate"
                : "Контакты / Партнерство"}
            </h3>
            <ul className="text-tertiary flex flex-col gap-y-2 font-medium">
              <li className="">
                {language == "RO" ? "Email" : "Электронная почта"}:
                office@justfix.md
              </li>
              <li className="">
                {language == "RO"
                  ? "Răspundem în maxim 24 de ore. Doar întrebări legate de parteneriate, serviciile oferite sau alte aspecte legate de business."
                  : "Отвечаем в течение 24 часов. Только вопросы, связанные с партнерством, предлагаемыми услугами или другими бизнес-аспектами."}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="py-2 bg-secondary text-tertiary sm:flex-col gap-y-2 sm:py-4 flex justify-between px-80 font-medium text-sm xl:px-56 lg:px-24 md:px-12 sm:px-6">
        <div className="  ">
          justfix.md © 2024.
          {language == "RO"
            ? "Toate drepturile rezervate."
            : "Все права защищены."}
        </div>
        <a href="https://scriptify.ro">made by Scriptify</a>
      </div>
      <motion.aside
        initial={{ x: "-100%" }}
        animate={{ x: openSideMenu ? 0 : "-100%" }}
        transition={{ duration: 0.3, stiffness: 0 }}
        className="fixed top-0 left-0 h-full w-full hidden sm:block bg-white z-50"
      >
        <div className="bg-secondary pt-12 pb-8 relative">
          <button
            onClick={() => setOpenSideMenu(false)}
            className="absolute top-5 right-5"
          >
            <GiHamburgerMenu className="text-3xl text-white" />
          </button>

          <div
            className="w-fit relative mx-auto"
            onClick={() => setOpenSideMenu(false)}
          >
            <Link
              to={"/"}
              className="flex gap-x-1 text-white items-baseline font-semibold text-5xl"
            >
              <img src={logo} className="w-8 h-auto" alt="logo" />
              JustFix
            </Link>
          </div>
        </div>
        <nav className="flex flex-col items-center gap-y-8 py-12 text-lg">
          <span onClick={() => setOpenSideMenu(false)}>
            <Link to={"/"}>{language == "RO" ? "Acasă" : "Домашняя"}</Link>
          </span>
          <span onClick={() => setOpenSideMenu(false)}>
            <Link to={"/produse"}>Magazin</Link>
          </span>
          <span onClick={() => setOpenSideMenu(false)}>
            <Link to={"/servicii"}>
              {language == "RO" ? "Servicii" : "Услуги"}
            </Link>
          </span>
          <span onClick={() => setOpenSideMenu(false)}>
            <Link to={"/servicii-la-distanta"}>
              {language == "RO"
                ? "Servicii la distanță"
                : "Услуги на расстоянии"}
            </Link>
          </span>
          <span onClick={() => setOpenSideMenu(false)}>
            <Link to={"/intrebari-frecvente"}>
              {language == "RO"
                ? "Întrebări Frecvente"
                : "Часто задаваемые вопросы"}
            </Link>
          </span>
          <span onClick={() => setOpenSideMenu(false)}>
            <Link to={"/contact"}>
              {language == "RO" ? "Contactează-ne" : "Связаться с нами"}
            </Link>
          </span>
        </nav>
      </motion.aside>
    </div>
  );
};

export default UserLayout;
