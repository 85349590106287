import React, { useState } from "react";
import { addToCart } from "../redux/cartSlice";
import { useDispatch } from "react-redux";
import { media_url } from "../constants";
import { Link } from "react-router-dom";
import { MdAddShoppingCart } from "react-icons/md";
import { motion } from "framer-motion";
import { BsCartCheck } from "react-icons/bs";

const ProductCard = ({ product }) => {
  const [isAdded, setIsAdded] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const dispatch = useDispatch();

  const AddItemToCartHandler = () => {
    setIsAdding(true);
    dispatch(addToCart({ id: product.id, quantity: 1 }));
    setIsAdded(true);
    setTimeout(() => {
      setIsAdding(false);
    }, 2000);
  };

  return (
    <motion.div
      key={product.id}
      initial={{ translateY: 0, boxShadow: "0px 4px 10px rgba(0,0,0,0.1)" }}
      whileHover={{ translateY: -4, boxShadow: "0px 4px 10px rgba(0,0,0,0.2)" }}
      transition={{
        duration: 0.2,
        ease: "easeInOut",
        stiffness: 100,
        bounce: 0.5,
      }}
      className="flex flex-col bg-black/10 rounded-md"
    >
      <Link to={`/produse/${product.slug}`} className="">
        <img
          src={media_url + product.image}
          alt={product.name}
          className="w-full rounded-t-md"
        />
        <p className="font-medium text-xl mt-2 px-4">{product.name}</p>
      </Link>
      <div className=" flex justify-between px-4 pb-4 mt-1 items-center">
        <div className="text-black/75 text-lg">{product.price} Lei</div>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className={`${
            isAdded ? "bg-green-500" : "bg-primary"
          } text-white rounded-full p-2`}
          onClick={AddItemToCartHandler}
        >
          {isAdded ? (
            <BsCartCheck className="text-xl" />
          ) : (
            <MdAddShoppingCart className="text-xl" />
          )}
        </motion.button>
      </div>
    </motion.div>
  );
};

export default ProductCard;
