import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url, media_url, defaultPageSEO } from "../constants";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { IoMdArrowDropright } from "react-icons/io";
import Sparkle from "react-sparkle";
import { addToCart } from "../redux/cartSlice";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { MdOutlineShoppingBag } from "react-icons/md";
import { MdAddShoppingCart } from "react-icons/md";
import { BsCartCheck } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import { LuMinus } from "react-icons/lu";
import parse from "html-react-parser";
import order from "../static/images/order.png";
import shipping from "../static/images/shipping.png";
import payment from "../static/images/payment.png";
import gift from "../static/images/gift.png";
import ProductCard from "../components/ProductCard";
import { useNavigate } from "react-router-dom";

const Product = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuggestedLoading, setIsSuggestedLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const { slug } = useParams();
  const [isSparkleShowing, setIsSparkleShowing] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${api_url}/products/${slug}/`)
      .then((res) => {
        setProduct(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        navigate("/404");
      });

    axios
      .get(`${api_url}/products/suggested/`)
      .then((res) => {
        const suggestedProducts = res.data.filter(
          (product) => product.slug !== slug
        );
        setSuggestedProducts(suggestedProducts);
        setIsSuggestedLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [window.location.href]);

  const AddItemToCartHandler = () => {
    dispatch(
      addToCart({
        id: product.id,
        quantity: quantity,
      })
    );
    setIsAdded(true);
    setIsSparkleShowing(true);
    setTimeout(() => {
      setIsSparkleShowing(false);
    }, 2000);
  };
  function OrderNowHandler() {
    dispatch(
      addToCart({
        id: product.id,
        quantity: quantity,
      })
    );
    navigate("/cos");
  }

  return (
    <div className="px-80 flex flex-col gap-y-4 mt-12 mb-20 xl:px-56 lg:px-24 md:px-12 sm:px-6">
      <Helmet>
        <title>{product ? product.seo_title : defaultPageSEO.title}</title>
        <meta
          name="description"
          content={
            product ? product.seo_description : defaultPageSEO.description
          }
        />
        <meta
          name="keywords"
          content={product ? product.seo_keywords : defaultPageSEO.keywords}
        />
        <link rel="canonical" href={`https://justfix.md/produse/${slug}`} />
      </Helmet>
      {(isLoading && <Skeleton height={20} width={"50%"} />) || (
        <div className="flex gap-x-2 sm:flex-wrap items-center text-lg font-medium">
          <Link to="/" className="text-primary">
            Acasă
          </Link>
          <IoMdArrowDropright />
          <Link to="/produse" className="text-primary">
            Magazin
          </Link>
          <IoMdArrowDropright />
          <p>{product?.name}</p>
        </div>
      )}
      {isLoading ? (
        <div className="flex gap-x-8 mt-2 sm:flex-col md:flex-col gap-y-8">
          <Skeleton height={400} width={400} className="sm:w-full" />
          <div className="w-3/5">
            <Skeleton height={20} width={"50%"} />
            <Skeleton height={20} width={"80%"} />
            <Skeleton height={20} width={"70%"} />
            <Skeleton height={20} width={"90%"} />
            <Skeleton height={20} width={"60%"} />
            <Skeleton height={20} width={"50%"} />
          </div>
        </div>
      ) : (
        <div className="flex gap-x-8 mt-2 sm:flex-col md:flex-col gap-y-8">
          <Carousel className="w-2/5 sm:w-full md:w-full">
            {product.images &&
              product.images.map((image) => (
                <div>
                  <img src={media_url + "/" + image} alt={product.name} />
                </div>
              ))}
          </Carousel>
          <div className="w-3/5 sm:w-full md:w-full">
            <h1 className="text-3xl font-bold">{product.name}</h1>
            <div className="flex flex-col gap-y-1 mt-2">
              {parse(product.description || "")}
            </div>
            <div className="flex gap-x-4 items-center mt-6 sm:justify-end md:justify-end">
              <div className="border-2 border-secondary justify-between border-solid rounded-full flex">
                <button
                  onClick={() => {
                    if (quantity <= 1) return;
                    setQuantity(quantity - 1);
                  }}
                  className="p-1 px-2"
                >
                  <LuMinus />
                </button>
                <input
                  type="number"
                  min="1"
                  max="1000"
                  value={quantity}
                  onChange={(e) => {
                    if (e.target.value < 1 || e.target.value > 1000) return;
                    setQuantity(e.target.value);
                  }}
                  className="p-1 outline-none w-10 text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />
                <button
                  onClick={() => {
                    if (quantity >= 1000) return;
                    setQuantity(quantity + 1);
                  }}
                  className="p-1 px-2"
                >
                  <GoPlus />
                </button>
              </div>
              <p className="text-2xl font-bold">
                {quantity * product.price} MDL
              </p>
            </div>
            <div className="flex gap-x-4 items-center mt-3 sm:justify-end md:justify-end sm:mt-5 md:mt-5">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className={`${
                  isAdded ? "bg-green-500" : "bg-primary"
                } text-white rounded-full p-2`}
                onClick={AddItemToCartHandler}
              >
                {isAdded ? (
                  <BsCartCheck className="text-xl" />
                ) : (
                  <MdAddShoppingCart className="text-xl" />
                )}
              </motion.button>
              <motion.button
                onClick={OrderNowHandler}
                whileHover={{ scale: 1.025 }}
                whileTap={{ scale: 0.975 }}
                className="bg-primary text-white p-2 font-medium rounded-full px-8 py-2 flex gap-x-2"
              >
                Comanda acum <MdOutlineShoppingBag className="text-xl" />
              </motion.button>
            </div>
          </div>
        </div>
      )}
      <h2 className="font-semibold text-3xl sm:text-2xl">
        Etapele comenzii tale
      </h2>
      <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-4 ">
        <div className="flex flex-col items-center bg-black/10 rounded-md px-4 py-8">
          <img src={order} alt={"comanda"} className="w-32" />

          <p className="text-black/75 text-center mt-4">
            Odată ce ai făcut comanda, te vom contacta pentru confirmare.
          </p>
        </div>
        <div className="flex flex-col items-center bg-black/10 rounded-md px-4 py-8">
          <img src={gift} alt={"cadou"} className="w-32" />

          <p className="text-black/75 text-center mt-4">
            Pentru fiecare comandă vei primi un cadou special de la noi.
          </p>
        </div>

        <div className="flex flex-col items-center bg-black/10 rounded-md px-4 py-8">
          <img src={shipping} alt={"livrare"} className="w-32" />

          <p className="text-black/75 text-center mt-4">
            Livrare gratuită în Chișinău și la comenzi de peste 500 Lei în
            Moldova.
          </p>
        </div>
        <div className="flex flex-col items-center bg-black/10 rounded-md px-4 py-8">
          <img src={payment} alt={"plata"} className="w-32" />
          <p className="text-black/75 text-center mt-4">
            Plata se face la primirea coletului, în numerar sau cu cardul.
          </p>
        </div>
      </div>
      <h2 className="font-semibold text-3xl sm:text-2xl mt-4">
        Te-ar mai putea interesa și
      </h2>
      <div className="grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8 gap-y-4">
        {isSuggestedLoading
          ? Array.from({ length: 4 }).map((_, index) => (
              <div key={index} className="flex flex-col bg-black/10 rounded-md">
                <Skeleton height={230} />
                <Skeleton height={50} />
              </div>
            ))
          : suggestedProducts.map((product) => (
              <ProductCard product={product} />
            ))}
      </div>
    </div>
  );
};

export default Product;
